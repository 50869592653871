import { useState } from "react";

export default function Faq(){
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const faqData = [
        { question: "Mi a különbség a sablon (pl. Wordpress) illetve az egy egyedileg készített weboldal között? ", answer: "Az ingyenes sablonok és bővítményeik nem feltétlenül pont azt, és nem pont úgy csinálják, mint amire neked, a vállalkozásodnak szüksége van, ezért többet használsz a kelleténél és ez az oldal jelentős lassulását okozhatja. Ha extra sablonokat és bővítményeket kezdesz el használni, azok megnövelik a költségeidet. Nyílt forráskód miatt nagyobb a sebezhetőség és a hackerek is szeretik támadni, mivel nagyobb a találati esélyük. Egyedileg készített weboldalaknál nagyobb a fejlesztési költség, de nincsenek rejtett plusz költségek, csak az és csak úgy készül el, amire neked szükséged van, nem kell a jövőben a bővítmények frissítésével foglalkoznod (újabb pénzeket kiadnod), és sokkal biztonságosabb " },
        { question: "Kinek ajánljuk és kinek nem ajánljuk az egyedi weboldalt?", answer: "Amennyiben egyszerű bemutatkozó oldalt szeretnél, vagy csak blogot vezetnél egyedi pénzkeresési lehetőség nélkül, arra kiváló a Worldpress, eredetileg is erre jött létre. Azonban, ha szeretnéd, hogy oldalad funkcionálisan egyedi legyen, ha szeretnéd a jövőben a vállalkozásod fejlődésének megfelelően profi funkciókkal modulárisan bővíteni, integrálni a különböző vállalati informatikai megoldásokkal (pl. munkafolyamat irányítás, partner kezelés stb.), akkor válassz egyedi weboldalt. " },
        { question: "Mennyi idő egy egyedi weboldal lefejlesztése? ", answer: "Egy átlagos weboldal lefejlesztésének technikai időszükséglete 2-3 hét. Ennél sokkal hosszabb időt kell szánni a tervezésre és az egyeztetésekre, hogy az készüljön el, amire Neked szükséged van és úgy nézzen ki, ahogyan szeretnéd. Itt nagyban számít az is, hogy Neked mennyi időd van a weboldalkészítésre, tudsz-e pár órán, napon belül visszajelezni a kérdésekre és jóváhagyni az elkészült elemeket. " },
        { question: "Mit kell Neked adnod egy honlaphoz?", answer: "Te ismered a vállalkozásod alapinformációit, tevékenységét, te tudod, hogy mit szeretnél a jövőben elérni, így ezeket Te tudod megadni az oldaladhoz. Természetesen, ha szükséged van rá, akkor segítünk. Kérdéseket, listákat adunk az adatok összegyűjtéséhez, megfogalmazzuk a szövegeket, megformázzuk a képeket, összevágjuk a videókat stb. " },
        { question: "Mi az a SEO és szükség van-e rá? ", answer: "Search Engine Optimization vagyis Keresőmotor Optimalizálás, rövidebben kereső optimalizálás. Nemcsak az organikus (nem fizetett) elérésekhez szükséges, hanem a későbbi hirdetésekhez is. Az oldalad alap technikai információit mindenképpen szükséges jól beállítani, ha a bővebb SEO-ba nem is szeretnél belemenni. Segítünk oldalad kezdő SEO kialakításában." },
        { question: "Mi van, ha nem tetszik az elkészült weboldal?", answer: "Olyan oldal fejlesztésébe bele se kezdünk, aminek a vázlatát te nem hagytad jóvá, így nem fordulhat elő, hogy neked nem tetsző dolog készül el. " },
        { question: "Mi van, ha nem tudom kezelni az elkészült oldalt?", answer: "Az elkészült oldal minden funkcióját megmutatjuk, készítünk használati segédletet, segítünk betanítani a kollégákat. " },
        { question: "Mit tegyek, ha hibák, vagy módosítási igények merültek fel az elkészült weboldalammal kapcsolatban? ", answer: "Nem engedjük el a kezedet, nem tűnünk el. A fejlesztési hibákat minden esetben ingyenesen javítjuk. Tapasztalataink szerint a leggondosabb tervezés ellenére is lehetnek olyan elemek, amelyek hiánya, vagy módosítási szükséglete a használat során merül fel, ami lehet csak pár nap, de lehet hónapokkal később is. Az ilyen igények kezelésére érdemes utógondozásra szerződést kötni. Ami igény szerint tartalmazhatja a honlap technikai frissítését, mentését, továbbá fejlesztési  órát, illetve tartalmi frissítést (SEO barát cikk írás, képek optimalizálása stb.).  " },
    ];

    const toggleFAQ = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <header className="faq-header">
                <h2>GYIK</h2>
            </header>
            <div className="line-2px _1px"></div>
            <div className="faq-content">
                <div className="faq-list">
                    {faqData.map((faq, index) => (
                        <div key={index} className={`faq-item ${openIndex === index ? "open" : ""}`}>
                            <button onClick={() => toggleFAQ(index)} className="faq-question">
                                {faq.question}
                                <span className="faq-icon">{openIndex === index ? "▲" : "▼"}</span>
                            </button>
                            <div className="faq-answer">
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );

}