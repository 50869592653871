import {Box, Button, Checkbox, Dialog, DialogTitle, DialogContent, Container, TextField, Stack, Typography, FormControlLabel} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {color} from "framer-motion";
import {ChangeEvent, FormEvent, useState} from "react";

function Footer() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [name, setName] = useState<string>('');

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Form adatainak JSON formátumba alakítása
        const jsonData = JSON.stringify(formData);

        // Adatok elküldése a szerverre
        fetch('https://4d3ylnmxsdkcauiwnar2dfsqhu0eyjwg.lambda-url.eu-west-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setName(formData.name)
                setOpen(true)
                setFormData(prevState => ({
                    ...prevState,
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                }));

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const label = (
        <Box component="span" sx={{color: "white"}}>Elfogadom az&nbsp;
            <a style={{color: 'white'}}
               href="/adatvedelmi.pdf"
               target="_blank"
                //onClick={onLinkClick}
            >
                adatkezelési tájékoztatóban
            </a>
            &nbsp;foglaltakat!
        </Box>
    )


    return(
        <footer>
            <Container id="kapcsolat" maxWidth={false} >
                <div className="FooterCover_glareContainer">
                    <svg className="Glare_glare__dVsae" viewBox="0 0 1500 850" preserveAspectRatio="xMidYMid slice"
                         xmlns="http://www.w3.org/2000/svg" style={{filter: 'url(#HeroCoverBlur)'}}>
                        <filter id="HeroCoverBlur" x="0" y="-100%" width="100%" height="350%">
                            <feGaussianBlur stdDeviation="120"></feGaussianBlur>
                        </filter>
                        <ellipse cx="230" cy="270" rx="230" ry="270" fill="#9d006c">
                            <animate attributeName="cx" values="230;250;230" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                            <animate attributeName="cy" values="270;450;270" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                        </ellipse>
                        <ellipse cx="520" cy="380" rx="222" ry="200" fill="#6906e0">
                            <animate attributeName="cx" values="520;520;520" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                            <animate attributeName="cy" values="380;530;380" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                        </ellipse>
                        <ellipse cx="670" cy="430" rx="210" ry="170" fill="#651596">
                            <animate attributeName="cx" values="670;670;670" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                            <animate attributeName="cy" values="430;650;430" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                        </ellipse>
                        <ellipse cx="1020" cy="410" rx="390" ry="245" fill="#7D45D5">
                            <animate attributeName="cx" values="1020;1080;1020" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                            <animate attributeName="cy" values="410;550;410" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                        </ellipse>
                        <ellipse cx="720" cy="450" rx="120" ry="100" fill="#0e22ce">
                            <animate attributeName="cx" values="720;640;720" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                            <animate attributeName="cy" values="450;560;450" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                        </ellipse>
                        <ellipse cx="1300" cy="480" rx="120" ry="100" fill="#C20286">
                            <animate attributeName="cx" values="1300;1400;1300" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                            <animate attributeName="cy" values="480;660;480" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                        </ellipse>
                        <ellipse cx="1100" cy="340" rx="130" ry="150" fill="#651596">
                            <animate attributeName="cx" values="1100;1050;1100" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                            <animate attributeName="cy" values="340;400;340" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                        </ellipse>
                    </svg>
                </div>
                <Container className="container" style={{transform: 'translate(0px, 0px)'}}>
                    <Box component="div" justifyContent="center" alignContent="center">
                        <Typography variant="h4" color="white">Lépj kapcsolatba velünk!</Typography>
                        <Typography variant="h6" color="white" gutterBottom>Ahhoz, hogy hatékonyan
                            segíthessünk, kérjük minél részletesebben töltsd ki űrlapunkat. Az első konzultáció ingyenes.</Typography>
                        <div className="newsletter-form  mx-auto newsletter--l1-2">
                            <form action="https://4d3ylnmxsdkcauiwnar2dfsqhu0eyjwg.lambda-url.eu-west-1.on.aws/" onSubmit={handleSubmit}
                                  method="POST">
                                <Stack direction="column" spacing={5} className="row">
                                    <TextField id="name"
                                               name="name"
                                               type="text"
                                               value={formData.name}
                                               onChange={handleChange}
                                               label="Név"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black'}}
                                               required />
                                    <TextField id="email"
                                               name="email"
                                               value={formData.email}
                                               onChange={handleChange}
                                               type="email"
                                               label="E-mail"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                               required />
                                    <TextField id="phone"
                                               name="phone"
                                               value={formData.phone}
                                               onChange={handleChange}
                                               type="text"
                                               label="Telefonszám"
                                               variant="filled"
                                               InputLabelProps={{ style: { color: "black" } }}
                                               sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                               required />
                                    <TextField
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        variant="filled"
                                        required
                                        label="Üzenet"
                                        multiline
                                        rows={4}
                                        InputLabelProps={{ style: { color: "black" } }}
                                        sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                    />
                                    <FormControlLabel required classes={{ asterisk: 'Mui-required' }} control={<Checkbox style ={{
                                        color: "white",
                                    }} />} label={label} />
                                    <div className="col-lg-12 mt-2">
                                        <button type="submit" className="message-button cta">
                                            Üzenet küldése
                                        </button>
                                    </div>
                                </Stack>
                            </form>
                        </div>


                    </Box>
                </Container>
            </Container>
            <Container maxWidth={false} style={{color: 'white', transform: 'translate(0px, 0px)'}}>
                <Container className="container">
                    <div className="footer-area footer-area--l1">
                        <Stack direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
                            <Box component="div">
                                <Typography variant="h6" color="white" gutterBottom>
                                    Célunk az, hogy büszke légy a honlapodra!
                                </Typography>

                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white" >További linkek</Typography>
                                <ul className="footer-widgets__list">
                                    <li><a href="/adatvedelmi.pdf" target="_blank">Adatkezelési
                                        Tájékoztató</a></li>
                                </ul>
                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white">Kapcsolat</Typography>

                                <ul className="footer-widgets__list footer-widgets--address">
                                    <li>
                                        <Stack direction="row" spacing={2} justifyContent="flex-start">
                                            <a href="https://facebook.com/netbrain.hu" target="_blank" aria-label="Kövess Facebookon"><FacebookIcon aria-label="Facebook icon" fontSize="large" sx={{color: 'white'}}/></a>
                                            <a href="https://instagram.com/netbrain_hu" target="_blank" aria-label="Kövess Instagramon"><InstagramIcon aria-label="Instagram icon" fontSize="large" sx={{color: 'white'}}/></a>
                                        </Stack>
                                    </li>
                                    <li>
                                        <LocationOnIcon sx={{paddingRight: '0.5rem'}}/>
                                        <Typography component="p" variant="body1">1101 Budapest, Kőbányai út 43/B</Typography>
                                    </li>
                                    <li>
                                        <PhoneIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="tel:36202576845"><Typography component="p" variant="body1">+36 20 257 6845</Typography></a>
                                    </li>
                                    <li>
                                        <MailOutlineIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="mailto:info@netbrain.hu"><Typography component="p" variant="body1">info@netbrain.hu</Typography></a>
                                    </li>
                                </ul>

                            </Box>
                        </Stack>
                    </div>
                    <div className="footer-copyright footer-copyright--l1">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7">
                                <div className="copyright text-center text-md-start">
                                    <Typography variant="body1" color="white" className="copyright__text">Copyrights © 2025 NetBrain</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle style={{backgroundColor: "black", color: "white"}}>
                    <Stack flexDirection="row" >
                        <div className="logo-wrap">
                                                    <span className="brain-logo">
                                                        <img
                                                            className="DesktopNavigation_invert__zqqWU"
                                                            src="/assets/img/brain.svg"
                                                            width="60"
                                                            height="60"
                                                            alt="logo"
                                                            draggable="false" />

                                                    </span>
                            <h2>NetBrain</h2>
                        </div>
                        <Typography variant="h4" gutterBottom sx={{ marginX: 'auto', transform: 'translateX(-100px)' }}>Adatok elküldése sikeres</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent style={{paddingTop: "30px"}}>
                    <Typography variant="h5" gutterBottom>Kedves {name}!</Typography>
                    <Typography variant="body1" gutterBottom>Köszönjük, hogy kitöltötted az űrlapot. Hamarosan felvesszük veled a kapcsolatot.</Typography>
                    <Typography variant="body1">Üdvözlettel,</Typography>
                    <Typography variant="body1"gutterBottom>Netbrain</Typography>
                </DialogContent>
            </Dialog>
        </footer>
    )
}

export default Footer