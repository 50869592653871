export default function HowToWork(){
    return(
        <section className="howtowork-section">
            <header className="howtowork-header">
                <h2>Hogyan dolgozunk</h2>
            </header>
            <div className="line-2px _1px"/>
            <div className="howtowork-description">20 éves fejlesztői tapasztalattal
                széles körű minőségi szolgáltatást nyújtunk. Meghallgatjuk problémáidat és közösen megtervezzük
                számodra a legmegfelelőbb informatikai megoldásokat, legyen szó profi egyedi készítésű
                weboldalról, komplex moduláris funkciókkal ellátott honlapról, vagy vállalati irányítási rendszerekről.</div>
            <div className="grid-container">
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">01</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Tanácsadás, konzultáció</h3>
                                <p>Minden sikeres weboldal alapja egy jól átgondolt koncepció. Egyéni konzultáció
                                    keretében felmérjük az igényeidet, meghatározzuk a célokat, és megvizsgáljuk a
                                    lehetőségeket. Segítünk tisztázni, hogy milyen funkciókra van szükség, milyen
                                    technológiák a legmegfelelőbbek, és hogyan érheted el a legjobb eredményt online
                                    jelenléteddel.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">02</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Stratégia, tervezés</h3>
                                <p>A weboldalkészítés egyik legfontosabb lépése a stratégia kidolgozása. Ebben a
                                    fázisban részletes tervet készítünk, amely tartalmazza a weboldal szerkezetét, a
                                    felhasználói élmény kialakítását, valamint a technikai megvalósítás lépéseit.
                                    Célunk, hogy a weboldalad ne csak esztétikus, hanem hatékony és könnyen használható
                                    is legyen.</p>
                            </div>
                        </div>
                    </div></div>
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">03</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Design, alkotás</h3>
                                <p>A vizuális megjelenés kulcsszerepet játszik a látogatók figyelmének megragadásában
                                    és a márkaépítésben. Egyedi, modern és felhasználóbarát dizájnt készítünk, amely
                                    tükrözi a vállalkozásod arculatát. Figyelünk az ergonómiára, a mobilbarát
                                    kialakításra és a reszponzivitásra, hogy minden eszközön tökéletes élményt nyújtson.</p>
                            </div>
                        </div>
                    </div></div>
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">04</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Kivitelezés, megvalósítás</h3>
                                <p>A tervezés után következik a weboldal fejlesztése. Modern technológiákkal dolgozunk,
                                    hogy a weboldalad gyors, biztonságos és könnyen kezelhető legyen. A fejlesztés
                                    során folyamatosan teszteljük a funkciókat, hogy minden tökéletesen működjön a
                                    végső átadáskor.</p>
                            </div>
                        </div>
                    </div></div>
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">05</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Publikálás, élesítés</h3>
                                <p>A weboldal elkészülte után gondoskodunk a zavartalan élesítésről. Segítünk a domain
                                    és tárhely beállításában, a keresőoptimalizálás alapjainak kialakításában és a
                                    biztonsági beállításokban. Az élesítés után ellenőrizzük a működést, hogy minden
                                    kifogástalan legyen az indulás pillanatában.</p>
                            </div>
                        </div>
                    </div></div>
                <div className="grid-item">
                    <div className="">
                        <div className="third-content-item" >
                            <hr/>
                            <span className="small-number">06</span>
                            <div className="spacer spacer-xs"></div>
                            <div className="item-description">
                                <h3>Support, utánkövetés</h3>
                                <p>A weboldal élesítése után sem engedjük el a kezed. Igény szerint biztosítunk
                                    karbantartást, frissítéseket és technikai támogatást.A leggondosabb tervezés
                                    ellenére is lehetnek olyan elemek, amelyek hiánya, vagy módosítási szükséglete a
                                    használat során merül fel, ami lehet csak pár nap, de lehet hónapokkal később is.
                                    Segítünk, hogy weboldalad mindig naprakész és hatékony maradjon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}