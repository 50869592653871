import {Box, Button, Checkbox, Dialog, DialogTitle, DialogContent, Container, TextField, Stack, Typography, FormControlLabel} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {color} from "framer-motion";
import {ChangeEvent, FormEvent, useState} from "react";

function LandingFooter() {

    return(
        <footer>
            <Container maxWidth={false} className="landing-footer">
                <div className="FooterCover_glareContainer">
                    <svg className="Glare_glare__dVsae" viewBox="0 0 1500 850" preserveAspectRatio="xMidYMid slice"
                         xmlns="http://www.w3.org/2000/svg" style={{filter: 'url(#HeroCoverBlur)'}}>
                        <filter id="HeroCoverBlur" x="0" y="-100%" width="100%" height="350%">
                            <feGaussianBlur stdDeviation="120"></feGaussianBlur>
                        </filter>
                        <ellipse cx="230" cy="270" rx="230" ry="270" fill="#9d006c">
                            <animate attributeName="cx" values="230;250;230" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                            <animate attributeName="cy" values="270;450;270" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                        </ellipse>
                        <ellipse cx="520" cy="380" rx="222" ry="200" fill="#6906e0">
                            <animate attributeName="cx" values="520;520;520" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                            <animate attributeName="cy" values="380;530;380" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                        </ellipse>
                        <ellipse cx="670" cy="430" rx="210" ry="170" fill="#651596">
                            <animate attributeName="cx" values="670;670;670" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                            <animate attributeName="cy" values="430;650;430" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                        </ellipse>
                        <ellipse cx="1020" cy="410" rx="390" ry="245" fill="#7D45D5">
                            <animate attributeName="cx" values="1020;1080;1020" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                            <animate attributeName="cy" values="410;550;410" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                        </ellipse>
                        <ellipse cx="720" cy="450" rx="120" ry="100" fill="#0e22ce">
                            <animate attributeName="cx" values="720;640;720" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                            <animate attributeName="cy" values="450;560;450" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                        </ellipse>
                        <ellipse cx="1300" cy="480" rx="120" ry="100" fill="#C20286">
                            <animate attributeName="cx" values="1300;1400;1300" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                            <animate attributeName="cy" values="480;660;480" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                        </ellipse>
                        <ellipse cx="1100" cy="340" rx="130" ry="150" fill="#651596">
                            <animate attributeName="cx" values="1100;1050;1100" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                            <animate attributeName="cy" values="340;400;340" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                        </ellipse>
                    </svg>
                </div>

                <Container className="container" style={{transform: 'translate(0px, 0px)'}}>
                    <div className="footer-area footer-area--l1">
                        <Stack direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
                            <Box component="div">
                                <Typography variant="h6" color="white" gutterBottom>
                                    Célunk az, hogy büszke légy a honlapodra!
                                </Typography>

                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white" >További linkek</Typography>
                                <ul className="footer-widgets__list">
                                    <li><a href="/adatvedelmi.pdf" target="_blank">Adatkezelési
                                        Tájékoztató</a></li>
                                </ul>
                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white">Kapcsolat</Typography>

                                <ul className="footer-widgets__list footer-widgets--address">
                                    <li>
                                        <Stack direction="row" spacing={2} justifyContent="flex-start">
                                            <a href="https://facebook.com/netbrain.hu" target="_blank" aria-label="Kövess Facebookon"><FacebookIcon aria-label="Facebook icon" fontSize="large" sx={{color: 'white'}}/></a>
                                            <a href="https://instagram.com/netbrain_hu" target="_blank" aria-label="Kövess Instagramon"><InstagramIcon aria-label="Instagram icon" fontSize="large" sx={{color: 'white'}}/></a>
                                        </Stack>
                                    </li>
                                    <li>
                                        <LocationOnIcon sx={{paddingRight: '0.5rem'}}/>
                                        <Typography component="p" variant="body1">1101 Budapest, Kőbányai út 43/B</Typography>
                                    </li>
                                    <li>
                                        <PhoneIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="tel:36202576845"><Typography component="p" variant="body1">+36 20 257 6845</Typography></a>
                                    </li>
                                    <li>
                                        <MailOutlineIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="mailto:info@netbrain.hu"><Typography component="p" variant="body1">info@netbrain.hu</Typography></a>
                                    </li>
                                </ul>

                            </Box>
                        </Stack>
                    </div>
                    <div className="footer-copyright footer-copyright--l1">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7">
                                <div className="copyright text-center text-md-start">
                                    <Typography variant="body1" color="white" className="copyright__text">Copyrights © 2024 NetBrain</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </footer>
    )
}

export default LandingFooter