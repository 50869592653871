import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ChangeEvent, CSSProperties, FormEvent, ReactNode, useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import LandingFooter from "../pages/common/LandingFooter";
import {useNavigate} from "react-router-dom";

function LandingCouponA() {
    const navigate = useNavigate();
    const { scrollYProgress } = useScroll();
    const [loading, setLoading] = useState(false);
    const [availableCoupon, setAvailableCoupon] = useState(0);

    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });

    useEffect(() => {
        // Fetch hívás csak az oldal betöltésekor fut le
        fetch( "https://34v5qcbinhcclvmau4s7wpz3ve0scsnp.lambda-url.eu-west-1.on.aws/", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setAvailableCoupon(data);
            })
            .catch((error) => {
                console.error("Hiba a küldés során. Próbálja meg később!");
            });
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const label = (
        <Box component="span">Elfogadom az&nbsp;
            <a
               href="/adatvedelmi.pdf"
               target="_blank"
                //onClick={onLinkClick}
            >
                adatkezelési tájékoztatóban
            </a>
            &nbsp;foglaltakat!
        </Box>
    )

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        // Form adatainak JSON formátumba alakítása
        const jsonData = JSON.stringify(formData);

        // Adatok elküldése a szerverre
        fetch('https://ujix2n2x2ncbx37uwfy4l4kwem0ltzdo.lambda-url.eu-west-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                navigate("/lp/kupon-thanks.html");
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };
    return(
        <>
            <Helmet>
                <title>Netbrain - 30% Kedvezmény</title>
                <meta name="description" content="netbrain weboldal audit" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <div className="page">
                <div className="content">
                    <Container className="header" maxWidth={false} sx={{width: '100vw', overflow: 'hidden',  marginX: 0}}>
                        <Container sx={{ marginY: '10px', width: '100%'}}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Box component="div" style={{ display: 'flex' }}>
                                    <Box component="img" src="/icon.png" width="100" alt="Netbrain Logo" className="icon" />
                                    <Typography
                                        component="h2"
                                        variant="h3"
                                        className="hero1"
                                        sx={{ paddingLeft: '10px', fontSize: { xs: '1.5rem', sm: '2rem' } }}
                                    >
                                        NetBrain
                                    </Typography>
                                </Box>
                             </Stack>
                        </Container>
                    </Container>
                    <Container sx={{marginY: "100px"}} className="blog-page">
                        <Container id="blog" className="blog">
                            <Stack direction="column" spacing={5}>
                                <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                                    Iratkozz fel és szerezd meg a 30%-os kuponodat, és hozd ki a legtöbbet vállalkozásodból!
                                </Typography>
                                <Stack direction={{xs: 'column-reverse', md: 'column-reverse' }} spacing={3}>
                                    <div>
                                        <p>
                                            Tudjuk, hogy vállalkozásod sikere számodra a legfontosabb – és mi itt vagyunk, hogy ebben segítsünk! 🎯 Most egy különleges ajánlatunk van számodra: <span className="bold">30% kedvezmény</span> bármelyik szolgáltatásunk árából, legyen szó:
                                        </p>
                                        <p>
                                            ✅ <span className="bold">Webfejlesztésről</span> – modern, gyors és reszponzív weboldalak, amik garantáltan kiemelik vállalkozásodat.<br/>
                                            ✅ <span className="bold">Egyedi szoftver fejlesztésről</span> – pontosan azt kapod, amire szükséged van, személyre szabva.<br/>
                                            ✅ <span className="bold">Web auditokról</span> – megtaláljuk, hol lehet még jobb az online jelenléted, és konkrét megoldásokat kínálunk.<br/>
                                        </p>
                                        <p>
                                            💡 <span className="bold">Miért válassz minket?</span>
                                        </p>
                                        <p>
                                            🔹 <span className="bold">Magas minőség</span> – Csak olyan munkát adunk ki a kezünkből, amire büszkék lehetünk.<br/>
                                            🔹 <span className="bold">Személyre szabott megoldások</span> – Minden projektünk egyedi, éppen úgy, mint a vállalkozásod.<br/>
                                            🔹 <span className="bold">Utógondozás</span> – Nem engedjük el a kezedet! Tapasztalataink alapján a leggondosabb tervezés mellett is, a használat során módosítási igények merülhetnek fel a vásárlóid/partnereid és a munkatársaid részéről is, a rendszer átadását követően akár 3 hónap, akár 12 hónap használat után.<br/>
                                        </p>
                                        <p>
                                            <span className="bold">Ne maradj le!</span><br/>
                                        </p>
                                        <p>
                                            Limitált számú kupon áll rendelkezésre.<br/>
                                            Az elérhető 100 kuponból már csak <span className="bold">{availableCoupon}</span> kupon maradt.<br/>
                                            Szerezd be most, és használd fel az ünnepek után. A karácsony legyen a családé és a pihenésé, az új évvel megújúlt erővel vesd bele magadat a vállalkozásod fejlesztésébe.<br/>
                                            <span className="bold">Az ajánlat 2025. február 21-ig megkötött szerződésre érvényes, és minimum 50.000 forintos szerződés esetén használható fel.</span> A több mérföldkőből álló fejlesztések esetén a kedvezmény csak a 2025-ben lezárt mérföldkövekre terjed  ki. <br/>
                                        </p>
                                        <p>
                                            Áraink
                                        </p>
                                        <p>
                                            Minden projekt egyedi, a te igényeidre szabva, így az árak is a szerint változnak, hogy te mit szeretnél:
                                            <ul>
                                                <li>Egyedileg – nem wordpress és hasonló weboldal kreátorral – készített weboldalak nettó 300 000 Ft-tól</li>
                                                <li>Egyedi szoftver fejlesztések, szoftverintergációk ( pl. ERP, CRM rendszerek) fejlesztői óradíj nettó 15 000 Ft-tól</li>
                                                <li>Honlap audit - Egy honlap audit során több szempontból vizsgáljuk meg és értékeljük ki a honlapodat (Technikai és tartalmi kérdések, biztonság, ergonómia, SEO ) nettó 150 000 Ft-tól</li>
                                            </ul>
                                        </p>
                                        <p>
                                            <span className="bold">Hogyan szerezheted meg a kupont?</span><br/>
                                            Add meg adataidat, kattints az alábbi gombra és azonnal küldjük a kuponodat! 👇
                                        </p>
                                    </div>
                                    <img src="/coupon.webp" alt="kupon" width="400" height="400" className="logo mobile-half" />
                                </Stack>
                                <form action="https://cw2csgr45jf47qstufttel4rxq0tpkkf.lambda-url.eu-west-1.on.aws" onSubmit={handleSubmit}
                                      method="POST">
                                    <Stack direction="column" spacing={5} className="row">
                                        <TextField id="name"
                                                   name="name"
                                                   type="text"
                                                   value={formData.name}
                                                   onChange={handleChange}
                                                   label="Keresztnév"
                                                   variant="filled"
                                                   InputLabelProps={{ style: { color: "black" } }}
                                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black'}}
                                                   required />
                                        <TextField id="email"
                                                   name="email"
                                                   value={formData.email}
                                                   onChange={handleChange}
                                                   type="email"
                                                   label="E-mail"
                                                   variant="filled"
                                                   InputLabelProps={{ style: { color: "black" } }}
                                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                                   required />
                                        <FormControlLabel required classes={{ asterisk: 'Mui-required' }} control={<Checkbox />} label={label} />
                                        <div className="col-lg-12 mt-2">
                                            <button type="submit" className="message-button cta pointer">
                                                Kérem a kupont
                                            </button>
                                        </div>
                                    </Stack>
                                </form>
                                <div>
                                    <p>
                                        Ne hagyd ki ezt a lehetőséget – együtt még sikeresebbé tesszük a vállalkozásod! 🚀
                                    </p>
                                    <p>
                                        <span className="bold">Az egyedi kuponkód nem névre szóló, átruházható (így ajándékba is athatod), de csak egyszer használható fel!</span> Egy projektre csak egy kupon vehető igénybe és más kedvezménnyel nem összevonható.
                                    </p>
                                </div>
                            </Stack>
                        </Container>
                    </Container>
                </div>
            <LandingFooter/>
            </div>
            {loading && (
                <div style={overlayStyle}>
                    <div style={styles.loaderContainer}>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </>
    )
}

const styles: { loaderContainer: React.CSSProperties } = {
    loaderContainer: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000, // Az előtérbe helyezi a jelzőt
    },
};

const overlayStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Féláttetsző fekete háttér
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
};

export default LandingCouponA