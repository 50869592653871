
export default function References() {

    return(
        <section className="reference-section" style={{backgroundColor: "white", color: "black"}}>
            <div className="head-line">
                <div className="container-works">
                    <div className="legal-top-head">
                        <div id="w-node-_4eea71c3-35e7-d01f-997f-912439d88256-414c16ec" className="_6-col-grid">
                            <div id="w-node-a52dcbf1-9ec2-ed13-98a4-d72637d71f15-414c16ec" className="h-caps16">
                                <h2 className="h1">Munkáink</h2>
                                {/*<p className="caps-16px">‘22-25</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-2px _1px"></div>
            </div>
            <div className="container-works big-bottom cst-top">
                <div className="works-flex">
                    <a data-w-id="6b427139-4477-535d-4454-a2bf65a19b7c" href="https://glassmax.hu/" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="/assets/img/sh_glassmax.webp"
                            loading="lazy"
                            alt="glassmax referencia" className="work-img"/>
                            <div className="_6-col-grid">
                                <div id="w-node-_6b427139-4477-535d-4454-a2bf65a19b7f-414c16ec" className="work-line _2"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_6b427139-4477-535d-4454-a2bf65a19b80-414c16ec"
                                        className="h1">Glassmax</h1>
                                    <div id="w-node-a5b234e1-59b9-67ed-2f70-17b8238a269d-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-_6b427139-4477-535d-4454-a2bf65a19b82-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">Tanácsadás</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Kivitelezés</p>
                                        </div>
                                        <div className="text-link">
                                            <p className="caps-16px ul">Weboldal</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-b6852b0b-218a-194e-9b83-6f4d64ee456d-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="4533d4a8-6652-d0f6-18ec-3be5dabb9e37" href="https://bsidetravellers.hu" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="/assets/img/sh_bsidetravellers.webp"
                            loading="lazy"
                            alt="bsidetravellers referencia" className="work-img"/>
                            <div className="_6-col-grid">
                                <div id="w-node-bddb8431-8760-0334-0587-b45ea09037df-414c16ec" className="work-line _1"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-e379db50-9912-5689-383a-b1ad65526706-414c16ec" className="h1">BSide
                                        Travellers</h1>
                                    <div id="w-node-ae15511b-c877-b7ed-acb0-cd7f5fc90300-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-_9505270e-ee6e-b22e-6c7c-38960143cc90-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">Stratégia</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Design</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Kivitelezés</p>
                                        </div>
                                        <div className="text-link">
                                            <p className="caps-16px ul">Weboldal</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-_2a99fc00-3174-6759-81ff-47a34d8e3726-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="b0d21b41-4354-aa8a-807b-b74f48934812"
                       className="work w-inline-block">
                        <img
                            src="/assets/img/sh_glassmax_erp.webp"
                            loading="lazy"
                            alt="glassmax erp referencia" className="work-img"/>
                            <div className="_6-col-grid">
                                <div id="w-node-b0d21b41-4354-aa8a-807b-b74f48934815-414c16ec" className="work-line _3"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-b0d21b41-4354-aa8a-807b-b74f48934816-414c16ec"
                                        className="h1">Glassmax ERP</h1>
                                    <div id="w-node-_45c34909-759c-6f7e-b7a1-6e68794322b1-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-b0d21b41-4354-aa8a-807b-b74f48934818-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">Tanácsadás</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Fejlesztés</p>
                                        </div>
                                        <div className="text-link" style={{opacity: '0'}}>
                                            <p className="caps-16px ul">Weboldal</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-aae6c21c-dc3d-d0eb-9d54-ab1a0f1780ae-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="b1fc84d2-548a-5cdd-668e-9c03877b88da"
                       className="work w-inline-block">
                        <img
                            src="/assets/img/sh_mancs.webp"
                            loading="lazy" width="960"
                            alt="mancs referencia"
                            className="work-img bottom"/>
                            <div className="_6-col-grid">
                                <div id="w-node-b1fc84d2-548a-5cdd-668e-9c03877b88dd-414c16ec" className="work-line _4"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-b1fc84d2-548a-5cdd-668e-9c03877b88de-414c16ec" className="h1">Mancs
                                        akadémia</h1>
                                    <div id="w-node-_31b012fc-0c6b-64db-10e9-cd41bd4cbfb3-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-b1fc84d2-548a-5cdd-668e-9c03877b88e0-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">Branding</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Design</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Fejlesztés</p>
                                        </div>
                                        <div className="text-link" style={{opacity: '0'}}>
                                            <p className="caps-16px ul">Weboldal</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    {/*<div className="_6-col-grid">
                        <div id="w-node-_1f7e2bc9-83d7-fc65-45e1-15ba301b47b9-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="4c8096b4-4ab6-4c30-6f3b-4a6d6c1c625e" href="https://swapzy.io/" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf10100c5dae3cdaa4a626_Swapzy.png"
                            loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 95vw, 26vw"
                            srcSet="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf10100c5dae3cdaa4a626_Swapzy-p-500.png 500w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf10100c5dae3cdaa4a626_Swapzy-p-800.png 800w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf10100c5dae3cdaa4a626_Swapzy.png 1185w"
                            alt="" className="work-img" style={{opacity: 0}}/>
                            <div className="_6-col-grid">
                                <div id="w-node-_4c8096b4-4ab6-4c30-6f3b-4a6d6c1c6261-414c16ec" className="work-line _5"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_4c8096b4-4ab6-4c30-6f3b-4a6d6c1c6262-414c16ec"
                                        className="h1">Swapzy</h1>
                                    <div id="w-node-_606875dc-2151-20bf-e0ae-5ed61cb12331-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div className="services-work work-page">
                                            <p className="caps-16px">Branding</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">UI/UX Design</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Webflow</p>
                                        </div>
                                        <div id="w-node-_4c8096b4-4ab6-4c30-6f3b-4a6d6c1c626d-414c16ec"
                                             className="text-link">
                                            <p className="caps-16px ul">Live WEBSITE</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-_3cc5ceba-fa02-2ab1-404c-1ad4d91a88d6-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="7f73b380-fc56-35b3-1290-db01ca3ce73e"
                       href="https://www.instagram.com/p/C5sV5b_CJBz/?img_index=1" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf0a217b9bc1f145e27305_Guide%20DAO.png"
                            loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 95vw, 26vw"
                            srcSet="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf0a217b9bc1f145e27305_Guide%20DAO-p-500.png 500w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf0a217b9bc1f145e27305_Guide%20DAO-p-800.png 800w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf0a217b9bc1f145e27305_Guide%20DAO-p-1080.png 1080w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf0a217b9bc1f145e27305_Guide%20DAO.png 1580w"
                            alt="" className="work-img" style={{opacity: 0}}/>
                            <div className="_6-col-grid">
                                <div id="w-node-_7f73b380-fc56-35b3-1290-db01ca3ce741-414c16ec" className="work-line _6"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_7f73b380-fc56-35b3-1290-db01ca3ce742-414c16ec" className="h1">Guide
                                        DAO</h1>
                                    <div id="w-node-_784e127e-9d9c-1b48-6722-c29a0675e0b5-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div className="services-work work-page">
                                            <p className="caps-16px">Graphic Design</p>
                                        </div>
                                        <div id="w-node-_7f73b380-fc56-35b3-1290-db01ca3ce74d-414c16ec"
                                             className="text-link">
                                            <p className="caps-16px ul">INST&nbsp;POst</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-e0606907-4671-5144-3454-2d2e2cdc9209-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="1ab7170a-9e7d-970c-6a8d-d7caa437da40"
                       href="https://www.behance.net/gallery/134258863/MODOHOUSE-modulnye-doma" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf4245de561af29d20ee2d_Modohouse.png"
                            loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 95vw, 26vw"
                            srcSet="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf4245de561af29d20ee2d_Modohouse-p-500.png 500w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf4245de561af29d20ee2d_Modohouse-p-800.png 800w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf4245de561af29d20ee2d_Modohouse.png 1186w"
                            alt="" className="work-img" style={{opacity: 0}}/>
                            <div className="_6-col-grid">
                                <div id="w-node-_1ab7170a-9e7d-970c-6a8d-d7caa437da43-414c16ec" className="work-line _7"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_1ab7170a-9e7d-970c-6a8d-d7caa437da44-414c16ec"
                                        className="h1">Modohouse</h1>
                                    <div id="w-node-_8f325ab8-21bb-ffc3-3971-c0536ac6861a-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div className="services-work work-page">
                                            <p className="caps-16px">UI/UX Design</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Tilda</p>
                                        </div>
                                        <div id="w-node-_1ab7170a-9e7d-970c-6a8d-d7caa437da4f-414c16ec"
                                             className="text-link">
                                            <p className="caps-16px ul">Behance Case</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-a7663047-e131-45aa-2b4a-8e85dceacaa0-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="72460fec-d1a9-d1df-e82c-a630974270f9"
                       href="https://www.behance.net/gallery/140660117/KITCHWILL-Landing-Page-Design" target="_blank"
                       className="work w-inline-block">
                        <img
                            src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3d25da62e24fb06965d0_kitchwill.png"
                            loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 95vw, 26vw"
                            srcSet="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3d25da62e24fb06965d0_kitchwill-p-500.png 500w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3d25da62e24fb06965d0_kitchwill-p-800.png 800w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3d25da62e24fb06965d0_kitchwill.png 1185w"
                            alt="" className="work-img bottom" style={{opacity: 0}}/>
                            <div className="_6-col-grid">
                                <div id="w-node-_72460fec-d1a9-d1df-e82c-a630974270fc-414c16ec" className="work-line _8"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_72460fec-d1a9-d1df-e82c-a630974270fd-414c16ec"
                                        className="h1">Kitchwill</h1>
                                    <div id="w-node-dc1efcaa-cb59-a99f-a421-7ccf73467888-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-_72460fec-d1a9-d1df-e82c-a630974270ff-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">UI/UX Design</p>
                                            <div className="quadro black"></div>
                                            <p className="caps-16px">Tilda</p>
                                        </div>
                                        <div className="text-link">
                                            <p className="caps-16px ul">Behance Case</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>
                    <div className="_6-col-grid">
                        <div id="w-node-c321a262-75cd-6159-303c-fe684d686491-414c16ec" className="line-2px _1px"></div>
                    </div>
                    <a data-w-id="18d4c7e5-0d36-abb9-38c3-95bd2f94d44f"
                       href="https://www.behance.net/gallery/134260699/4LOFT-furniture-eccomerce-website-design"
                       target="_blank" className="work w-inline-block">
                        <img
                            src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3caaa805fecc9c953317_4loft%20(1).png"
                            loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 95vw, 26vw"
                            srcSet="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3caaa805fecc9c953317_4loft%20(1)-p-500.png 500w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3caaa805fecc9c953317_4loft%20(1)-p-800.png 800w, https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66bf3caaa805fecc9c953317_4loft%20(1).png 1185w"
                            alt="" className="work-img bottom" style={{opacity: 0}}/>
                            <div className="_6-col-grid">
                                <div id="w-node-_18d4c7e5-0d36-abb9-38c3-95bd2f94d452-414c16ec" className="work-line _9"
                                     style={{opacity: 1}}>
                                    <h1 id="w-node-_18d4c7e5-0d36-abb9-38c3-95bd2f94d453-414c16ec"
                                        className="h1">4loft®</h1>
                                    <div id="w-node-_2b7621c9-cebd-ed92-6540-5e91315b512d-414c16ec"
                                         className="services-link" style={{opacity: 0}}>
                                        <div id="w-node-_18d4c7e5-0d36-abb9-38c3-95bd2f94d455-414c16ec"
                                             className="services-work work-page">
                                            <p className="caps-16px">UI/UX Design</p>
                                        </div>
                                        <div className="text-link">
                                            <p className="caps-16px ul">Behance Case</p>
                                            <img
                                                src="https://cdn.prod.website-files.com/66476c64e241589e9d182b69/66a4c7b273ee81ae782c9d77_sm-ar.svg"
                                                loading="lazy" alt="" className="sm-ar"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </a>*/}
                </div>
            </div>

        </section>
    )
}