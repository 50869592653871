
export default function Services(){

    return(
        <section>
            <div className="ServiceCover_serviceCover">
            <div className="ServiceCover_container px-sm-0">
                <h2 className="col-12 px-0 fadeInUpContent"
                    style={{backgroundImage: `url("/assets/img/gradient.jpg")`, transform: 'translate(0px, 0px)', opacity: '1'}}>Egy weboldal nem hoz nagy változást
                    vállalkozásod életében, viszont egy jó weboldal igen.</h2>

            </div>
            </div>
        </section>
    )
}