import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import Header from "../common/Header";
import React from "react";
import {Container} from "@mui/material";
import Footer from "../common/Footer";
import NewsLetterPopup from "../../components/NewsLetterPopup";

function BlogQuickWebpageTest() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Teszteld le honlapod működését</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">Teszteld le honlapod működését 10 perc alatt saját magad</h1>
                    </header>
                    <div className="post-headline">
                    </div>
                    <div className="post-image center">
                        <img src="/blog_weblap_gyorsteszt.webp" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <p>
                            Ha a honlapod lassú, nehézkes, vagy nem nyújt megfelelő felhasználói élményt, az a
                            potenciális ügyfelek elvesztéséhez vezethet. Egy egyszerű önellenőrzéssel gyorsan
                            felfedezheted, ha az oldalad nem felel meg az alapvető elvárásoknak, például nehézkesen
                            navigálható, hibásak a linkek, vagy nem olvasható a tartalom.
                        </p>
                        <p>
                            Ez a módszer segít feltárni azokat a problémákat, amelyeket talán észre sem vettél, és ha
                            már tudod, hogy mi nem jó az oldaladon, akkor célirányosan lépéseket tehetsz a javításuk
                            érdekében. Az eredmény? Egy gyorsabb, könnyebben használható, ügyfelek számára vonzóbb
                            weboldal, ami hosszú távon több bevételt és nagyobb elégedettséget hoz! Ne várj addig, míg
                            a problémák elriasztják a látogatókat – tesztelj még ma!
                        </p>
                        <p>
                            <span className="bold">Ez a teszt teljesen önállóan, eszközök nélkül is elvégezhető, és
                                egyértelmű eredményeket ad.</span>
                        </p>
                        <h4>
                            1., Milyen gyorsan tölt be a weboldalad?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A lassú betöltés rontja a felhasználói
                            élményt és SEO-eredményeket.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Nyisd meg az oldalt böngészőben, és
                            számold meg, mennyi időbe telik, hogy a fő tartalom látható legyen.
                            <ul>
                                <li><span className="bold">3 pont:</span> Nagyon gyors (2 másodperc alatt).</li>
                                <li><span className="bold">2 pont:</span> Elfogadható (3-5 másodperc).</li>
                                <li><span className="bold">1 pont:</span> Lassú (6+ másodperc).</li>
                            </ul>
                        </p>
                        <h4>
                            2., Az oldal helyesen jelenik meg különböző eszközökön? (reszponzivitás)
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A Google a mobilbarát weboldalakat előrébb
                            sorolja a keresési eredményekben.<br/>
                            👉 <span className="bold">Hogyan mérheted?</span> Nézd meg az oldalt különböző eszközökön
                            (asztali számítógép, mobil és tablet (ez utóbbi opcionális)
                            <ul>
                                <li><span className="bold">3 pont:</span> Az elvárásaidnak megfelelően jelenik meg
                                    mobilon, tableten és asztali gépen is.</li>
                                <li><span className="bold">2 pont:</span> Kisebb problémákat látsz egyes eszközökön, de
                                    tudod használni.</li>
                                <li><span className="bold">1 pont:</span> Jelentős hibák vannak, például nem olvasható,
                                    szétesik a design mobilon, bizonyos tartalmak kitakarják egymást, gombok, funkciók
                                    nem töltődnek be, nem tudsz rábökni, nem nyílik meg stb...</li>
                            </ul>
                        </p>
                        <h4>
                            3., Hány kattintás (görgetés) kell a legfontosabb információ eléréséhez?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> Manapság az emberek nem szeretnek sokat
                            bajlódni egy oldallal, ha nem találják meg gyorsan az információt, amit keresnek, tovább
                            állnak...<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Határozd meg, mi a legfontosabb az
                            oldaladon pl. Árlista, vagy kapcsolati űrlap. Majd számold meg, hogy hány kattintással
                            (görgetéssel) tudsz eljutni hozzá.
                            <ul>
                                <li><span className="bold">3 pont:</span> 1-2 kattintás (görgetés).</li>
                                <li><span className="bold">2 pont:</span> 3-4 kattintás (görgetés).</li>
                                <li><span className="bold">1 pont:</span> 5 vagy több kattintás (görgetés).</li>
                            </ul>
                        </p>
                        <h4>
                            4., A weboldalon lévő linkek működnek?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A keresőmotorok hátrébb sorolják azokat az
                            oldalakat, amelyeken hibás linkek vannak, és természetesen a potenciális ügyfelek sem
                            szeretik...<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Kattints végig a fontos linkeket,
                            és figyeld, hogy a várt oldalra vezetnek-e.
                            <ul>
                                <li><span className="bold">3 pont:</span> Az összes link működik, oda vezet, ahová
                                    kell.</li>
                                <li><span className="bold">2 pont:</span> Minden link működik, de nem mind aktuális,
                                    friss infókra vezet, vagy nem releváns oldalra.</li>
                                <li><span className="bold">1 pont:</span> Hibás, hiányzó linkek, nem nyílnak meg
                                    stb.</li>
                            </ul>
                        </p>
                        <h4>
                            5., Van az oldalon bármilyen elavult információ? Milyen gyakran frissíted a tartalmat?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A friss tartalom vonzza a látogatókat és
                            segít nekik az elérésedben.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Ellenőrizd az eseménynaptárt,
                            árlistát vagy más időérzékeny tartalmat. Illetve nézd át azokat az adataidat (kiemelten az
                            elérhetőségeidet), amiben a honlapod elkészítése óta módosulás történt.
                            <ul>
                                <li><span className="bold">3 pont:</span> Rendszeresen (havonta vagy gyakrabban)
                                    frissítesz. Nincs, elavult információ, minden adat naprakész.</li>
                                <li><span className="bold">2 pont:</span> Évente egyszer-kétszer frissítesz. Pár adat
                                    régi vagy irreleváns.</li>
                                <li><span className="bold">1 pont:</span> A honlapodat az elkészülte óta nem
                                    frissítetted. Több elavult információ van, például régi események, lejárt
                                    ajánlatok, rossz elérhetőség stb.</li>
                            </ul>
                        </p>
                        <h4>
                            6., A weboldal tartalmaz-e működésképtelen gombokat, funkciókat? Az űrlapok helyesen
                            működnek?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A potenciális ügyfeleid gyorsan leléphetnek
                            az oldaladról, ha nem tudják megfelelően használni az oldaladat.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Teszteld a gombokat, funkciókat és
                            nézd meg, hogy kitölthető-e megfelelően az űrlap, kapsz-e visszaigazolást stb.
                            <ul>
                                <li><span className="bold">3 pont:</span> Minden funkció, gomb, űrlap stb. Az
                                    elvártaknak megfelelően működik.</li>
                                <li><span className="bold">2 pont:</span> Egy-két funkció, hibás, de nem zavaró. Az
                                    űrlap, ha nem is teljesen jól, de kitölthető, működik.</li>
                                <li><span className="bold">1 pont:</span> Több fontos funkció nem működik. Az űrlapok
                                    nem működnek, vagy nem küldik el az adatokat megfelelően.</li>
                            </ul>
                        </p>
                        <h4>
                            7., Minden aloldal elérhető a főmenüből? (illetve fordítva: az aloldalakról is vissza tudsz
                            jutni a főmenübe?)
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> A keresőmotorok (SEO) és a potenciális
                            ügyfeleid számára is fontos, hogy az információk rendezetten és gyorsan elérhetőek legyenek
                            és könnyű legyen a navigáció. <br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Nézd át a főmenü elemeit, és
                            ellenőrizd, hogy minden releváns aloldalt tartalmaz-e.
                            <ul>
                                <li><span className="bold">3 pont:</span> Igen, minden fontos aloldal könnyen elérhető.
                                    Minden aloldalról vissza lehet jutni a főmenübe.</li>
                                <li><span className="bold">2 pont:</span> 1-2 fontos aloldal csak kereséssel található
                                    meg. A főmenübe 1-2 aloldal esetén, csak több kattintással lehet visszajutni.</li>
                                <li><span className="bold">1 pont:</span> Több fontos aloldal nem érhető el a
                                    főmenüből. Az aloldalról több esetben sem lehet azonnal visszajutni a főmenübe.</li>
                            </ul>
                        </p>
                        <h4>
                            8., A honlapod kulcsszavai a Google találati listáján hol hoznak? (SEO)
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> Ahhoz, hogy organikusan (hirdetések nélkül)
                            is eljuss potenciális ügyfelekhez, fontos, hogy a megfelelő kulcsszavaid legyenek és
                            azokkal a keresőmotorok találati listáján jó helyezéseket érj el.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Vedd elő a kulcsszólistádat és írd
                            be a benne lévő szavakat, kifejezéseket, a Google keresőjébe.
                            <ul>
                                <li><span className="bold">3 pont:</span> Legfontosabb kulcsszavaim tekintetében a
                                    Google találati lista első két oldalán szerepelek (figyelem a Google első találatai
                                    általában fizetett hirdetések)</li>
                                <li><span className="bold">2 pont:</span> 1-2 kulcsszavam alapján a Google találati
                                    listán szerepelek, de nem az első két oldalon.</li>
                                <li><span className="bold">1 pont:</span> Nem ismerem a honlapom kulcsszavait.
                                    Egyáltalán mik azok a kulcsszavak?</li>
                            </ul>
                        </p>
                        <h4>
                            9., Hierarchikusan jól besorolt, egységes formázású a weboldalad szövege?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> Ahhoz, hogy a potenciális ügyfeleid
                            megfelelően tudjanak navigálni, illetve a kellő információkat tudja a honlapod hatékonyan
                            átadni, szükséges, hogy az oldalad átlátható, egységes megjelenésű legyen, egyértelműek
                            legyenek a fő üzenetek és a cselekvésre felhívások.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Nézd meg a címsorokat,
                            bekezdéseket, funkciókat és gombokat a fő és aloldalakon, asztali számítógépen és mobilon is.
                            <ul>
                                <li><span className="bold">3 pont:</span> A betűtípusok, méretek és stílusok mindenhol
                                    egységesek. Egyértelműen kiemelkednek a szövegből a fő üzenetek és a CTA gombok
                                    (cselekvésre felhívás: pl. küld be, töltsd le stb.).</li>
                                <li><span className="bold">2 pont:</span> Néhol eltér a formázás, a CTA gombok
                                    formázása nem figyelemfelhívó, de egyértelműen azonosíthatóak.</li>
                                <li><span className="bold">1 pont:</span> Kaotikus, eltérő betűtípusok, nehéz
                                    olvashatóság, zavaró színhasználat.</li>
                            </ul>
                        </p>
                        <h4>
                            10., Mennyire modern a weboldalad designja? Illetve a webdesign lekövette vállalkozásod
                            arculatának változásait?
                        </h4>
                        <p>
                            👉 <span className="bold">Miért számít?</span> Az első benyomás igenis számít: az elavult
                            design elriaszthatja a látogatókat. Ráadásul, ha honlapod jelentősen különbözik
                            vállalkozásod többi arculati elemétől (pl. fizikai boltod megjelenése, szórólapod,
                            névjegykártyád stb. kinézete) a potenciális ügyfelek elbizonytalanodhatnak azon, hogy jó
                            honlapra tévedtek-e.<br/>
                            👉 <span className="bold">Hogyan ellenőrizheted?</span> Gondold végig mikor frissítetted a
                            honlapodat. Illetve hasonítsd össze honlapodat a fizikai megjelenéseddel pl.  szórólapod,
                            névjegykártyád stb.
                            <ul>
                                <li><span className="bold">3 pont:</span> Honlapod professzionális és naprakész,
                                    tükrözi a márkád arculatát.</li>
                                <li><span className="bold">2 pont:</span> Weboldaladat évek óta nem frissítetted,
                                    funkcionálisan működik, a design hasonlít a fizikai arculati elemeidre.</li>
                                <li><span className="bold">1 pont:</span> Az oldalad 2010-es években készült, és / vagy
                                    a vállalkozásod arculati elemei jelentősen eltérnek a honlapod design-jától.</li>
                            </ul>
                        </p>
                        <p>&nbsp;</p>
                        <h4>
                            Értékelés
                        </h4>
                        <p>
                            <ul>
                                <li><span className="bold">26–30 pont:</span> Gratulálunk, az oldalad jól karbantartott! Apróbb finomításokat lehet tenni. Ne felejtsd el a továbbiakban is rendszeresen ellenőrizni és frissíteni az oldalad.</li>
                                <li><span className="bold">16–25 pont:</span> Sajnos a honlapoddal problémák vannak, amelyeket mielőbb meg kell oldani. Ne halogasd, mert minél többet vársz, annál több potenciális ügyfelet veszthetsz, és annál többe fog kerülni a hibák kijavítása.</li>
                                <li><span className="bold">10–15 pont:</span> Az oldal jelentős fejlesztésre szorul. Szakember segítségével gondold végig, hogy érdemes-e ezt az oldalt fejleszteni vagy inkább egy új honlap készítésével jársz jobban.</li>
                            </ul>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <span className="bold">A technikai-tartalmi felmérésben segíthet egy webaudit, amely
                                során felmérjük az oldalad teljesítményét, használhatóságát, biztonságát, és keresőmotor optimalizáltságát.
                            </span>
                        </p>
                        <div style={{color: "#54abc9"}}>
                            <p>
                                <a href="/idopont-foglalas.html">Webauditot kérek, nézzük át együtt a honlapomat!</a>
                            </p>
                        </div>
                    </div>
                </article>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogQuickWebpageTest;