import React, { useState, useEffect } from "react";

interface Consent {
    essential: boolean;
    analytics: boolean;
    marketing: boolean;
}

const tagID = "GTM-NDBH7SJV";

const loadGoogleTagManager = (): void => {
    if (document.getElementById("gtm-script")) return;

    const inlineScriptConsent = document.createElement('script');
    inlineScriptConsent.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}

    gtag('consent', 'default', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
    });
    `;
    document.head.appendChild(inlineScriptConsent);

    const inlineScript = document.createElement('script');
    inlineScript.id = "gtm-script";
    inlineScript.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${tagID}');
              `;
    document.head.appendChild(inlineScript);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagID}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
};

const CookieBanner: React.FC = () => {
    const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
    const [isBannerOptionsVisible, setIsBannerOptionsVisible] = useState<boolean>(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [consent, setConsent] = useState<Consent>({
        essential: true, // mindig igaz, nem lehet elutasítani
        analytics: false,
        marketing: false,
    });


    useEffect(() => {
        loadGoogleTagManager();
        // Ellenőrizzük, hogy a hozzájárulás meg van-e adva
        const storedConsent = localStorage.getItem('cookieConsent');
        if (!storedConsent) {
            setIsBannerVisible(true);
        } else {
            setConsent(JSON.parse(storedConsent));
        }
    }, []);

    const handleConsentChange = (category: keyof Consent): void => {
        setConsent((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    const handleAcceptAll = () => {
        const newConsent = {
            essential: true,
            analytics: true,
            marketing: true,
        };
        setConsent(newConsent);
        localStorage.setItem('cookieConsent', JSON.stringify(newConsent));
        setIsBannerVisible(false);
        setIsBannerOptionsVisible(false);
        setIsSettingsOpen(false);
        if (window.gtag) {
        window.gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        })}
    };

    const handleSavePreferences = () => {
        localStorage.setItem('cookieConsent', JSON.stringify(consent));
        setIsBannerVisible(false);
        setIsBannerOptionsVisible(false);
        setIsSettingsOpen(false);
        if (window.gtag) {
        window.gtag('consent', 'update', {
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied'
        })}
    };

    const openSettings = () => {
        setIsSettingsOpen(true);
    };
    const showBannerOptions = () => {
        setIsBannerOptionsVisible(true);
    };


    if (!isBannerVisible && !isSettingsOpen) {
        return (
            <div className="cookie-settings" onClick={openSettings}>
                <img src="/assets/img/cookie_white.png" alt="cookie icon" width={40} />
            </div>
        );
    }

    return (
        <div className="cookie-banner">
            <div className="cookie-banner-header">
            <img src="/assets/img/cookie_white.png" alt="cookie icon" width={60}/>
            <h3 className="cookie-banner-title">Sütiket használunk</h3>
            </div>
            <p className="cookie-banner-description">
                Az oldal jobb használhatósága érdekében sütiket használunk.
            </p>
            {isBannerOptionsVisible &&
                <div className="cookie-options">
                    <label>
                        <input
                            type="checkbox"
                            checked={consent.essential}
                            disabled
                        />
                        Alapvető (kötelező)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={consent.analytics}
                            onChange={() => handleConsentChange('analytics')}
                        />
                        Analitikai
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={consent.marketing}
                            onChange={() => handleConsentChange('marketing')}
                        />
                        Marketing
                    </label>
                    <button onClick={handleSavePreferences}>Kiválasztottak mentése</button>
                </div>
            }
            <div className="cookie-buttons">
                <button onClick={handleAcceptAll}>Elfogadom a sütiket</button>
                <button onClick={showBannerOptions} style={{backgroundColor: '#112724', border: 'none', color: '#E6F9FE'}}>További lehetőségek</button>
            </div>
        </div>
    );
};

export default CookieBanner;
