import {useEffect, useState } from "react";

export default function Header() {
    const [isActive, setIsActive] = useState(false);
    const [scrollOpacity, setScrollOpacity] = useState(0);

    const toggleMobileNav = () => {
        console.log(isActive);
        setIsActive(!isActive);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            // 0px-től 100px-ig fokozatosan növeljük az opacity értékét
            const newOpacity = Math.min(scrollY / 150, 0.8); // 0-tól 1-ig változik
            setScrollOpacity(newOpacity);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header>
            <div>
                <nav className="Navigation Navigation_black">
                    <div className="Navigation_desktopNavigationWrap" style={{
                        backgroundColor: `rgba(0, 0, 0, ${scrollOpacity})`,
                        /*transition: "background-color 0.3s ease-in-out"*/
                    }}>
                        <div className="container-fluid">
                            <div className="DesktopNavigation d-none d-md-block">
                                <div className="container-fluid px-0">
                                    <div className="row no-gutters">
                                        <div className="col-3 col-md-2 col-lg-2 d-flex align-items-center">
                                            <a href="/">
                                                <div className="logo-wrap">
                                                    <span className="brain-logo">
                                                        <img
                                                            className="DesktopNavigation_invert__zqqWU"
                                                            src="/assets/img/brain.svg"
                                                            width="60"
                                                            height="60"
                                                            alt="logo"
                                                            draggable="false" />

                                                    </span>
                                                    <h2>NetBrain</h2>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-9 col-md-10 d-flex align-items-center justify-content-end">
                                            <div className="DesktopNavigation_navItem">
                                                <a href="/blog.html"><span>Blog</span></a>
                                            </div>
                                            <div className="DesktopNavigation_navItem">
                                                <a href="#kapcsolat"><span>Kapcsolat</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Navigation_mobileNavigationWrap">
                        <div className={`MobileNavigation MobileNavigation_black mobile-nav d-flex d-md-none ${isActive ? "MobileNavigation_active" : ""}`}>
                            <div className={`MobileNavigation_navTop ${isActive ? "MobileNavigation_active" : ""}`}>
                                <div className="d-md-none MobileNavigation_mobLogo">
                                    <a href="/">
                                        <div className="logo-wrap">
                                            <span className="brain-logo">
                                                <img
                                                    className="DesktopNavigation_invert__zqqWU"
                                                    src="/assets/img/brain.svg"
                                                    width="60"
                                                    height="60"
                                                    alt="logo"
                                                    draggable="false" />
                                            </span>
                                            <h2>NetBrain</h2>
                                        </div>
                                    </a>
                                </div>
                                <div className={`MobileNavigation_hamburgerIcon hamburgerIcon ${isActive ? "MobileNavigation_active" : ""}`} onClick={toggleMobileNav}>
                                    <div className="MobileNavigation_boxContainer">
                                        <div className="MobileNavigation_box1 box"></div>
                                        <div className="MobileNavigation_box2 box"></div>
                                        <div className="MobileNavigation_box3 box"></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`MobileNavigation_navContent ${isActive ? "MobileNavigation_active" : ""}`}>
                                <div className="container px-0">
                                    <div className="MobileNavigation_navContentTop">
                                        <div className="MobileNavigation_navigationUrlWrap">
                                            <a href="/blogs">
                                                <div className="MobileNavigation_navItem "><span>Blog</span></div>
                                            </a>
                                            <a href="/contact">
                                                <div className="MobileNavigation_navItem "><span>Kapcsolat</span></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="MobileNavigation_navContentBottom__REmmJ">
                                        <div className="MobileNavigation_followUsTitle__udqqf"></div>
                                        <div className="MobileNavigation_socialIcons__uQXNP"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}