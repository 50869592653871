import Marquee from "react-fast-marquee";
import React from "react";

export default function Reviews(){
    const cards = [
        {text: '"Alapos munkát végeztek, minden kérdésemnek, kérésemnek utánajártak"', name: 'János'},
        {text: '"Mi folyamatos karbantartási szerződést kötöttünk, mivel rendszeresen merülnek fel újabb és újabb fejlesztési igények a kollégáink és a vevőink részéről is."', name: 'Judit'},
        {text: '"Akadozott a weboldalunk és ezért kértünk honlap auditot. Gondosan átvizsgálták az oldalunkat és közérthetően elmagyarázták a problémákat, és megoldották azokat."', name: 'Zita'},
        {text: '"Egyedi szoftvert kértünk a munkafolyamatunkra és maradéktalanul elkészült"', name: 'Kiss Béla'},
        /*{text: '"Ez még plusz egy vélemény"', name: 'Lajos'}*/
    ];

    return(
        <section className="review-section">
            <header className="review-header">
                <h2>Rólunk mondták</h2>
            </header>
            <div className="line-2px _1px"></div>
            <div className="review-content">
                <div className="marquee-wrapper cards-container">
                    <Marquee pauseOnHover={true} direction={"right"}>
                        {cards.map((card, index) => (
                            <div className="card" key={index} style={{backgroundImage: "url('/right-quotation-mark.webp')", backgroundRepeat: 'no-repeat', lineHeight: '160%'}}>
                                <p>{card.text}</p>
                                <h4>{card.name}</h4>
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>
        </section>
    )
}