
export default function Hero() {

    return(
        <section>
            <div className="HeroCover_heroCover__nk2lm">
                <div className="HeroCover_glareContainer__LXAd_">
                    <svg className="Glare_glare__dVsae" viewBox="0 0 1500 850" preserveAspectRatio="xMidYMid slice"
                         xmlns="http://www.w3.org/2000/svg" style={{filter: 'url(#HeroCoverBlur)'}}>
                        <filter id="HeroCoverBlur" x="0" y="-100%" width="100%" height="350%">
                            <feGaussianBlur stdDeviation="120"></feGaussianBlur>
                        </filter>
                        <ellipse cx="230" cy="270" rx="230" ry="270" fill="#9d006c">
                            <animate attributeName="cx" values="230;250;230" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                            <animate attributeName="cy" values="270;450;270" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                        </ellipse>
                        <ellipse cx="520" cy="380" rx="222" ry="200" fill="#6906e0">
                            <animate attributeName="cx" values="520;520;520" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                            <animate attributeName="cy" values="380;530;380" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                        </ellipse>
                        <ellipse cx="670" cy="430" rx="210" ry="170" fill="#651596">
                            <animate attributeName="cx" values="670;670;670" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                            <animate attributeName="cy" values="430;650;430" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                        </ellipse>
                        <ellipse cx="1020" cy="410" rx="390" ry="245" fill="#7D45D5">
                            <animate attributeName="cx" values="1020;1080;1020" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                            <animate attributeName="cy" values="410;550;410" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                        </ellipse>
                        <ellipse cx="720" cy="450" rx="120" ry="100" fill="#0e22ce">
                            <animate attributeName="cx" values="720;640;720" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                            <animate attributeName="cy" values="450;560;450" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                        </ellipse>
                        <ellipse cx="1300" cy="480" rx="120" ry="100" fill="#C20286">
                            <animate attributeName="cx" values="1300;1400;1300" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                            <animate attributeName="cy" values="480;660;480" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                        </ellipse>
                        <ellipse cx="1100" cy="340" rx="130" ry="150" fill="#651596">
                            <animate attributeName="cx" values="1100;1050;1100" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                            <animate attributeName="cy" values="340;400;340" calcMode="spline" keyTimes="0; 0.5; 1"
                                     keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                        </ellipse>
                    </svg>
                </div>
                <div className="HeroCover_container__axkIp container px-sm-0">
                    <h1 className="col-12 px-0 fadeInUpContent"
                        style={{transform: 'translate(0px, 0px)', opacity: '1'}}>Növeld profitod egyedi fejlesztésű
                        weboldallal</h1>
                    <p className="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 px-0 fadeInUpContent hero-p"
                       style={{transform: 'translate(0px, 0px)', opacity: '1'}}>Szem előtt tartjuk a vállalkozásod egyedi
                        igényeit, hatékonyan támogatjuk a gyors ütemű fejlődésed!
                        Egyedi céges weboldal készítés, profi funkciókkal modulárisan bővíthető honlap készítés, komplex
                        informatikai megoldások kisvállalkozásoknak.</p>
                    <div
                        className="HeroCover_cta__xC0dq col-8 col-sm-7 col-md-5 offset-md-4 col-lg-4 offset-lg-6 px-0 fadeInUpContent"
                        style={{transform: 'translate(0px, 0px)', opacity: '1'}}>
                    <span className="CtaButton_ctaButtonWrap__VBSCf homepage-get-in-touch">
                        <a href="#kapcsolat" className="CtaButton_ctaButton__RXRzm CtaButton_black__mGAd6  px-0">
                            <span className="CtaButton_ctaButtonTitle__sPrtD">Beszéljünk</span>
                            <span className="CtaButton_arrow__eRjhQ CtaButton_right__3l7dH">
                                <img src="/assets/img/ctaArrow-white.svg" alt="CTA Button Arrow" width="25" height="auto"
                                     loading="lazy"/>
                            </span>
                        </a>
                    </span>
                    </div>
                </div>
            </div>
        </section>
    )
}