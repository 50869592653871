import Hero from "./Home/Hero";
import Header from "./common/Header";
import References from "./Home/References";
import Services from "./Home/Services";
import WorksWithUs from "./Home/WorksWithUs";
import HowToWork from "./Home/HowToWork";
import Reviews from "./Home/Reviews";
import Faq from "./Home/Faq";
import Footer from "./common/Footer";

export default function Home(){

    return(
        <>
            <Header/>
            <Hero/>
            <Services/>
            <HowToWork/>
            <Faq/>
            <WorksWithUs/>
            {/*<div style={{height: '100vh'}}></div>*/}
            <References/>
            <Reviews/>

            <Footer/>
        </>
    )

}