import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ReactNode} from "react";
import Footer from "./Footer";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "./Header";
import LandingFooter from "../pages/common/LandingFooter";

function LandingSeoAuditThanks() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Kupon</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div className="page">
                <div className="content">
                    <Header/>
                    <Container sx={{marginY: "100px"}} className="blog-page">
                        <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                            🎉 Gratulálunk! Már csak egy lépés választ el a 30%-os kuponodtól! 🎉
                        </Typography>
                        <Container id="blog" className="blog">
                            <Stack direction="column" spacing={5}>
                                <p>
                                    Hamarosan megérkezik az e-mail a kedvezményes kuponoddal a megadott e-mail címedre. 📧
                                </p>
                                <p>
                                    📅 Ne feledd: Az ajánlat 2025. január 6. és február 21. között megkötötött, 50 000 Ft feletti szerződésre érvényes. Az egyedi kuponkód nem névre szóló, átruházható (így ajándékba is athatod), de csak egyszer használható fel! Egy projektre csak egy kupon vehető igénybe és más kedvezménnyel nem összevonható.
                                </p>
                                <p>
                                    ✨ Addig is gondolkodj el, hogy vállalkozásod melyik területe szorul fejlesztésre:
                                </p>
                                <p>
                                    ✅ Egyedi weboldal fejlesztés – modern, gyors és egyedi megoldásokkal.<br/>

                                    ✅ Szoftverfejlesztés – pont azt kapod, amire szükséged van.<br/>

                                    ✅ Honlap audit – kihozzuk a maximumot jelenlegi oldaladból.
                                </p>
                                <p>
                                    📩 Ha nem találod a kupont, nézd meg a spam/promóciók mappát is! Mindenképpen tedd át az e-mailt a fő postaládádba, hogy a további e-mailekről nehogy lemaradj!
                                </p>
                                <p>
                                    🔗 Irány a postaláda! 🚀<br/>
                                    Amennyiben a postaládádban nem találod a levelet, akkor írj az <a href="mailto:info@netbrain.hu">info@netbrain.hu</a> e-mail címre és elküldjük Neked külön a kupont.
                                </p>
                                <p>
                                    Köszönjük:
                                <br/>
                                    Netbrain csapata
                                </p>
                                <p>
                                    Amíg megérkezik a levél ...
                                </p>
                                <p>
                                    Blogbejegyzéseink:
                                    <ul>
                                        <li>
                                            <a href="/blog/seo-roviden-erthetoen.html">SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés</a>
                                        </li>
                                        <li>
                                            <a href="/blog/landing_vagy_weboldal.html">Landing oldalra vagy weboldalra van szükséged?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/mennyibe_kerul_egy_weblap.html">Mennyibe kerül 2024-ben egy weblap Magyarországon?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/weblap_frissites.html">Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését válaszd?</a>
                                        </li>
                                    </ul>
                                </p>
                            </Stack>
                        </Container>
                    </Container>
                </div>
            <LandingFooter/>
            </div>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
        </>
    )
}

export default LandingSeoAuditThanks