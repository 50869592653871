import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ReactNode} from "react";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "../common/Header";
import LandingFooter from "../common/LandingFooter";

function AppointmentThanks() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Időpont foglalás</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div className="page">
                <div className="content">
                    <Header/>
                    <div className="HeroCover_heroCover__nk2lm">
                        <div className="HeroCover_glareContainer__LXAd_">
                            <svg className="Glare_glare__dVsae" viewBox="0 0 1500 850" preserveAspectRatio="xMidYMid slice"
                                 xmlns="http://www.w3.org/2000/svg" style={{filter: 'url(#HeroCoverBlur)'}}>
                                <filter id="HeroCoverBlur" x="0" y="-100%" width="100%" height="350%">
                                    <feGaussianBlur stdDeviation="120"></feGaussianBlur>
                                </filter>
                                <ellipse cx="230" cy="270" rx="230" ry="270" fill="#9d006c">
                                    <animate attributeName="cx" values="230;250;230" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                                    <animate attributeName="cy" values="270;450;270" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5"></animate>
                                </ellipse>
                                <ellipse cx="520" cy="380" rx="222" ry="200" fill="#6906e0">
                                    <animate attributeName="cx" values="520;520;520" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                                    <animate attributeName="cy" values="380;530;380" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6"></animate>
                                </ellipse>
                                <ellipse cx="670" cy="430" rx="210" ry="170" fill="#651596">
                                    <animate attributeName="cx" values="670;670;670" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                                    <animate attributeName="cy" values="430;650;430" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7"></animate>
                                </ellipse>
                                <ellipse cx="1020" cy="410" rx="390" ry="245" fill="#7D45D5">
                                    <animate attributeName="cx" values="1020;1080;1020" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                                    <animate attributeName="cy" values="410;550;410" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="7.5"></animate>
                                </ellipse>
                                <ellipse cx="720" cy="450" rx="120" ry="100" fill="#0e22ce">
                                    <animate attributeName="cx" values="720;640;720" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                                    <animate attributeName="cy" values="450;560;450" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="6.5"></animate>
                                </ellipse>
                                <ellipse cx="1300" cy="480" rx="120" ry="100" fill="#C20286">
                                    <animate attributeName="cx" values="1300;1400;1300" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                                    <animate attributeName="cy" values="480;660;480" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="5.5"></animate>
                                </ellipse>
                                <ellipse cx="1100" cy="340" rx="130" ry="150" fill="#651596">
                                    <animate attributeName="cx" values="1100;1050;1100" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                                    <animate attributeName="cy" values="340;400;340" calcMode="spline" keyTimes="0; 0.5; 1"
                                             keySplines="0.4 0 0.6 1; 0.4 0 0.6 1" repeatCount="indefinite" dur="4.5"></animate>
                                </ellipse>
                            </svg>
                        </div>
                    <Container sx={{marginY: "100px", transform: 'translate(0px, 0px)'}} className="blog-page">
                        <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                            Köszönjük bizalmadat!
                        </Typography>
                        <Container id="blog" className="appointment-page">
                            <Stack direction="column" spacing={5}>
                                <p>
                                    A konzultációhoz szükséges Google Meet linket tartalmazó e-mail hamarosan megérkezik a megadott e-mail címre.
                                </p>
                                <p>
                                    Ha nem találnád a beérkezett levelek közt, nézd meg a promóciók és a spam mappát, ha itt sem találod, akkor írj az <a href="mailto:info@netbrain.hu">info@netbrain.hu</a> e-mail címre és elküldjük Neked.
                                </p>
                                <p>
                                    Köszönjük:
                                <br/>
                                    Netbrain csapata
                                </p>
                                <p>
                                    Amíg megérkezik a levél ...
                                </p>
                                <p>
                                    Legutóbbi blogbejegyzéseink:
                                    <ul>
                                        <li>
                                            <a href="/blog/seo-roviden-erthetoen.html">SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés</a>
                                        </li>
                                        <li>
                                            <a href="/blog/landing_vagy_weboldal.html">Landing oldalra vagy weboldalra van szükséged?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/mennyibe_kerul_egy_weblap.html">Mennyibe kerül 2024-ben egy weblap Magyarországon?</a>
                                        </li>
                                        <li>
                                            <a href="/blog/weblap_frissites.html">Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését válaszd?</a>
                                        </li>
                                    </ul>
                                </p>
                            </Stack>
                        </Container>
                    </Container>
                    </div>
                </div>
            <LandingFooter/>
            </div>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
        </>
    )
}

export default AppointmentThanks